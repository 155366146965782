<template>
    <div class="" v-if="dashboardData">
        <div>
            <ul class="bar-container px-0">
                <li v-for="(bar, index) in bars" :key="index" class="horizontal-bar-container">
                    <div class="horizontal-bar">
                        <div class="horizontal-bar-fill" :id="'bar' + index" :style="{ backgroundColor: bar.color }">
                        </div>
                    </div>
                    <div class="info-container">
                        <div class="bars-container">
                            <div class="name-square" :style="{ backgroundColor: bar.color }"></div>
                            <p class="bar-name">{{ bar.name }}</p>
                        </div>
                        <p class="bar-price">{{ bar.price }} ₸</p>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HorizontalChart',
    props: {
        dashboardData: {
            type: Object,  // Мы ожидаем объект
            default: () => ({})
        },
    },
    data() {
        return {

            bars: [],
            // bars: [
            //     { name: 'В зале', color: '#13414E', width: 60, price: '2 073 280' },
            //     { name: 'Доставка', color: '#006D5A', width: 30, price: '1 037 280' },
            //     { name: 'На вынос', color: '#14BF91', width: 10, price: '207 328' },
            // ],
        };
    },
    mounted() {
        // После монтирования компонента инициируем анимацию для каждой полоски
        // this.updateBars();
        // this.animateBars();
        console.log('Received dashboardData in HozizonChart:', this.dashboardData); // Лог данных
    },
    watch: {
        dashboardData: {
            immediate: true,
            handler(newData) {
                if (newData && Object.keys(newData).length > 0) {
                    this.updateBars();
                }
            }
        }
    },
    computed: {
        formattedBars() {
            const pickUpTypeOrder = {
                'InHouse': 1,    // В зале
                'Delivery': 2,   // Доставка
                'SelfPickUp': 3  // Навынос
            };

            const revenues = this.dashboardData.totalChannelRevenues || [];

            // Пропускаем последний элемент и считаем сумму
            const totalRevenueSum = revenues.slice(0, -1).reduce((sum, channel) => {
                return sum + channel.totalRevenue.amount;
            }, 0);

            // Отформатированные данные с сортировкой по исходному pickUpType
            return revenues
                .slice(0, -1)
                .map((channel, index) => {
                    const percentOfTotal = (channel.totalRevenue.amount / totalRevenueSum) * 100;

                    return {
                        originalPickUpType: channel.pickUpType, // Сохраняем исходный тип для сортировки
                        name: this.formatPickUpType(channel.pickUpType),  // Форматируем тип для отображения
                        color: this.getColor(channel.pickUpType),  // Метод для получения цвета
                        width: percentOfTotal.toFixed(0),  // Вычисляем процент и округляем
                        price: `${channel.totalRevenue.amount.toLocaleString('ru-RU')}`,  // Форматируем цену
                    };
                })
                .sort((a, b) => pickUpTypeOrder[a.originalPickUpType] - pickUpTypeOrder[b.originalPickUpType]);  // Сортировка по оригинальному pickUpType
        }
    },
    methods: {
        updateBars() {
            this.bars = this.formattedBars;
            console.log(this.bars[0]);
            console.log("текст")
            this.$nextTick(() => {
                this.animateBars();
            });
        },
        animateBars() {
            this.bars.forEach((bar, index) => {
                const barElement = document.getElementById('bar' + index);
                if (barElement) {
                    barElement.style.width = '0%';
                    setTimeout(() => {
                        barElement.style.transition = 'width 1s ease';
                        barElement.style.width = bar.width + '%';
                    }, 100);
                }
            });
        },
        formatPickUpType(type) {
            const typeMap = {
                'InHouse': 'В зале',
                'Delivery': 'Доставка',
                'SelfPickUp': 'На вынос',
            };
            return typeMap[type] || type;
        },
        getColor(index) {
            const typeMapColor = {
                'InHouse': '#13414E',
                'Delivery': '#006D5A',
                'SelfPickUp': '#14BF91',
            };
            return typeMapColor[index] || index;
        },
        calculateBarWidth(amount) {
            // Пример расчета ширины полосы (может быть заменен на более сложный расчет)
            const maxAmount = Math.max(...this.dashboardData.totalChannelRevenues.map(item => item.totalRevenue.amount));
            return (amount / maxAmount) * 100;
        }
    },
};
</script>

<style scoped>
.bars-container {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.bar-name {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.781vw;
    color: #323c4d;
    margin-bottom: 0px;
}

.bar-price {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.677vw;
    color: #848484;
    margin-bottom: 0px;
}

.bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 67px; */
    height: 100%;
    gap: 33px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.horizontal-bar {
    background: #f0f0f0;
    max-width: 330px;
    width: 100%;
    height: 10px;
}

.horizontal-bar-fill {
    height: 10px;
    width: 0%;
    border-radius: 2px;
    /* Изначально ширина 0% */
}

.horizontal-bar-container {
    list-style-type: none;
}

.name-container {
    display: flex;
    align-items: center;
}

.name-container p {
    line-height: 0px;
    margin-top: 15px;
    margin-left: 10px;
}

.name-square {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-right: 7px;
}
</style>